import React, { useEffect } from "react"
import { AppWrapper } from "../components/AppWrapper"
import Footer from "../components/ui/Footer"
import { Header } from "../components/Header"
import { WidthContainer } from "../components/WidthContainer"
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"
import {graphql, Link} from "gatsby"
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function IndexPage({ location }) {

    const { t } = useTranslation();
    // analytics - page viewed event
    useEffect(() => {
        if(!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_privacy_policy_page_viewed)
    }, [firebase])

    return (
        <AppWrapper>
            <Header {...{ location }} />
            <div id="background"
                 {...{
                     style: {
                         placeItems: "center",
                         background: "#102543",
                         // height: "100%",
                         color: "white"
                     },
                 }}>
                <WidthContainer center>


                    <div   {...{ style: { height: "200px" }, }}>

                    </div>
                    <h1 style={{ fontSize: "24px", margin: "48px 0" }}>{t('privacy_policy.title')}</h1>

                    <div className="elementor-element elementor-element-7b309a5c elementor-widget elementor-widget-text-editor" data-id="7b309a5c" data-element_type="widget" data-widget_type="text-editor.default">
                        <div className="elementor-widget-container" style={{ fontWeight: 400, overflowWrap: 'break-word' }}>
                            <p>{t('privacy_policy.intro')}</p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.site_info"></Trans></p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.definitions.user"></Trans></p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.definitions.affiliate"></Trans></p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.processing_data"></Trans></p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.compliance"></Trans></p>
                            <br/>
                            <p><Trans i18nKey="privacy_policy.data_definition"></Trans></p>
                            <br/>
                            {/* ARTICLE 1 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_1.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_1,engagement_statement')}</p>
                            <br/>
                            <ul style={{ listStyle: 'inside' }}>
                                <li>{t('privacy_policy.article_1.no_obligation')}</li>
                                <li>{t('privacy_policy.article_1.purpose_of_collection')}</li>
                                <li>{t('privacy_policy.article_1.minimize_data')}</li>
                                <li>{t('privacy_policy.article_1.keep_data_accurate')}</li>
                                <li>{t('privacy_policy.article_1.ensure_security')}</li>
                                <li>{t('privacy_policy.article_1.data_retention')}</li>
                                <li>{t('privacy_policy.article_1.employee_training')}</li>
                                <li>{t('privacy_policy.article_1.security_breach_notification')}</li>
                            </ul>
                            <br/>
                            {/* ARTICLE 2 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_2.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_2.data_collection_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_2.data_types_collected')}</p>
                            <br/>
                            <ol type='a' style={{ listStyle: 'lower-alpha inside' }}>
                                <li>
                                    <b>{t('privacy_policy.article_2.data_list.item_a_title')}</b> {t('privacy_policy.article_2.data_list.item_a_content')}
                                </li>
                                <li>
                                    <b>{t('privacy_policy.article_2.data_list.item_b_title')}</b> {t('privacy_policy.article_2.data_list.item_b_content')}
                                </li>
                            </ol>
                            <br/>
                            <p>{t('privacy_policy.article_2.data_accuracy_statement')}</p>
                            <br/>
                            {/* ARTICLE 3 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_3.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_3.tracking_technologies_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_3.cookies_definition')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_3.tracking_list_intro')}</p>
                            <br/>
                            <p><b>{t('privacy_policy.article_3.necessary_trackers_title')}</b></p>
                            <p>{t('privacy_policy.article_3.necessary_trackers_content')}</p>
                            <br/>
                            <p><b>{t('privacy_policy.article_3.functional_trackers_title')}</b></p>
                            <p>{t('privacy_policy.article_3.functional_trackers_content')}</p>
                            <br/>
                            <p><b>{t('privacy_policy.article_3.audience_performance_trackers_title')}</b></p>
                            <p>{t('privacy_policy.article_3.audience_performance_trackers_content')}</p>
                            <br/>
                            <p><b>{t('privacy_policy.article_3.advertising_targeting_trackers_title')}</b></p>
                            <p>{t('privacy_policy.article_3.advertising_targeting_trackers_content')}</p>
                            <br/>
                            <p><b>{t('privacy_policy.article_3.tracking_management_title')}</b></p>
                            <p>{t('privacy_policy.article_3.tracking_management_content')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_3.browser_cookie_settings')}</p>
                            <ul style={{ listStyle: 'inside' }}>
                                <li>Chrome : <Link to='https://support.google.com/accounts/answer/61416?hl=fr'>https://support.google.com/accounts/answer/61416?hl=fr</Link></li>
                                <li>Internet Explorer : <Link to='http://windows.microsoft.com/fr-fr/windows7/block-enable-or-allow-cookies'>http://windows.microsoft.com/fr-fr/windows7/block-enable-or-allow-cookies</Link></li>
                                <li>Firefox : <Link to='https://support.mozilla.org/fr/kb/activer-desactiver-cookies'>https://support.mozilla.org/fr/kb/activer-desactiver-cookies</Link></li>
                                <li>Safari : <Link to='https://support.apple.com/fr-fr/HT1677'>https://support.apple.com/fr-fr/HT1677</Link></li>
                            </ul>
                            {/* ARTICLE 4 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_4.article_title', { returnObjects: true })}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_4.data_collection_intro')}</p>
                            <br/>
                            <table style={{ border: '1px solid white', tableLayout: 'fixed', width: '100%' }}>
                                <thead style={{ borderBottom: '1px solid white' }}>
                                <tr>
                                    <th style={{ borderRight: '1px solid white', padding: '10px' }}>{t('privacy_policy.article_4.table_headers.purposes')}</th>
                                    <th style={{ padding: '10px' }}>{t('privacy_policy.article_4.table_headers.legal_bases')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {t('privacy_policy.article_4.table_rows', { returnObjects: true }).map((row,  index) => (
                                    <tr key={index}>
                                        <td style={{ borderRight: '1px solid white', borderBottom: '1px solid white', padding: '20px' }}>
                                            {row.purposes.split(';').map((purpose, index) => (
                                                <p key={index}>{purpose.trim()}</p>
                                            ))}
                                        </td>
                                        <td style={{ padding: '20px', borderBottom: '1px solid white' }}>
                                            {row.legal_bases.split(';').map((legalBase, index) => (
                                                <p key={index}><Trans>{legalBase.trim()}</Trans></p>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <br/>
                            <p>{t('privacy_policy.article_4.data_collection_notification')}</p>
                            <br/>
                            {/* ARTICLE 5 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_5.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_5.data_retention_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_5.determination_criteria')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_5.cookie_data_retention')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_5.post_retention_actions')}</p>
                            <br/>
                            {/* ARTICLE 6 */}
                            <h2 style={{ fontSize: "18px" }}>{t('privacy_policy.article_6.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_6.rights_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_6.contact_instructions')}</p>
                            <ul style={{ listStyle: 'inside' }}>
                                <li>{t('privacy_policy.article_6.contact_method_email')}<Link to='mailto:contact@behappiz.com' >contact@behappiz.com</Link></li>
                                <li>{t('privacy_policy.article_6.contact_method_website')}<Link to='https://www.behappiz.com'>www.behappiz.com</Link>{t('privacy_policy.article_6.contact_method_website_suffix')}</li>
                            </ul>

                            <br/>
                            <p>{t('privacy_policy.article_6.specific_rights_title')} :</p>
                            <br/>
                            <ul style={{ listStyle: 'inside' }}>
                                {t('privacy_policy.article_6.specific_rights', { returnObjects: true }).map((right, index) => (
                                    <React.Fragment key={index}>
                                        <li>
                                            <b>{right.title}</b>
                                            <br/>
                                            <span>
                                                <Trans i18nKey={right.description} components={[<a href="https://www.cnil.fr/" target="_blank" rel="noopener noreferrer"></a>]} />
                                            </span>
                                        </li>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </ul>
                            <br/>
                            {/* ARTICLE 7 */}
                            <h2 style={{ fontSize: '18px' }}>{t('privacy_policy.article_7.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_7.data_security_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_7.security_measures')}</p>
                            <br/>
                            {/* ARTICLE 8 */}
                            <h2 style={{ fontSize: '18px' }}>{t('privacy_policy.article_8.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_8.data_recipients_intro')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_8.data_recipients_list')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_8.data_servers_location')}</p>
                            <br/>
                            {/* ARTICLE 9 */}
                            <h2 style={{ fontSize: '18px' }}>{t('privacy_policy.article_9.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_9.contact_info')}</p>
                            <ul style={{ listStyle: 'inside' }}>
                                <li>{t('privacy_policy.article_9.contact_method_email')}<Link to='mailto:contact@behappiz.com' >contact@behappiz.com</Link></li>
                                <li>{t('privacy_policy.article_9.contact_method_website')}<Link to='https://www.behappiz.com'>www.behappiz.com</Link>{t('privacy_policy.article_9.contact_method_website_suffix')}</li>
                            </ul>
                            <br/>
                            {/* ARTICLE 10 */}
                            <h2 style={{ fontSize: '18px' }}>{t('privacy_policy.article_10.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_10.target_audience')}</p>
                            <br/>
                            <p>{t('privacy_policy.article_10.parental_consent')}</p>
                            <br/>
                            {/* ARTICLE 11 */}
                            <h2 style={{ fontSize: '18px' }}>{t('privacy_policy.article_11.article_title')}</h2>
                            <br/>
                            <p>{t('privacy_policy.article_11.modification_notice')}</p>
                            <br/>
                            <p>{t('privacy_policy.date')}</p>
                        </div>
                    </div>
                </WidthContainer>

            </div>
            <Footer />
        </AppWrapper>
    );
}
